.pagination {
    border-radius: 10px;
}

.pagination a {
    background-color: #1b0921;
    border: 1px solid rgba(255, 255, 255, 0.06);
    color: white;
    padding: 0.5rem 1rem
}

.pagination a:hover {
    background-color: #10001780;
    border: 1px solid rgba(255, 255, 255, 0.06);
    color: white;
}

.pagination a:focus {
    background-color: #10001780;
    border: 1px solid rgba(255, 255, 255, 0.06);
    color: white;
}

.pagination .active .page-link {
    background-color: #100017;
    border: 1px solid rgba(255, 255, 255, 0.06);
    padding: 0.5rem 1rem
}