@import './theme.scss';
@import './farm.scss';
@import './assets.scss';
@import './common.scss';
@import './vaults.scss';
@import './mvx-dapp-sdk.scss';
@import './pagination.scss';

.vesta_x_main {
    font-family: $font-poppins;
    background: $primary-bg-color;
}

/* header */
.vesta_x_header {
    background: $secondary-bg-color;
    border-bottom: 1px solid $header-border-color;
    padding: 8px 0px !important;

    @media (max-width: 992px) {
        padding: 20px 0px 40px 0px !important;
    }
}

.vesta_x_logo {
    width: 90px;
    height: 90px;

    border: 1px solid $header-border-color;
    border-radius: 100px;
    background: $secondary-bg-color;

    display: flex;
    align-items: center;

    margin-top: -45px;
    position: relative;
    z-index: 2;

    img {
        width: 100%;
    }

    @include lg-device() {
        display: none;
    }
}

.navbar-nav {
    align-items: center;
}

.nav-menu-wrap {
    justify-content: end;
}

.navbar-toggler {
    border: none !important;
    outline: none !important;
    font-size: 2rem !important;
    color: white !important;
    box-shadow: none !important;
}

.vestax_navbar_but {
    margin: 0rem 0.25rem;
    padding: 6px 15px;

    font-size: 1.05rem;
    font-weight: 300;
    text-align: center;
    text-decoration: none !important;
    color: white !important;

    transition: all 0.2s ease-in;
    cursor: pointer;

    &:hover {
        color: $primary !important;
    }
}

.vestax_navbar_but_focus {
    color: $primary !important;
    border-radius: 100px;
    background: $secondary-bg-color;
}

.vestax_navbar_dropdown_focus {
    .dropdown-toggle {
        color: $primary !important;
        border-radius: 100px;
        background: $secondary-bg-color;
    }
}

@media (max-width: 992px) {
    .nav-menu-wrap.navbar-collapse.collapse.show {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.nav-link {
    color: white !important;
}

.custom-navbar-box {
    background: #2a172f;
    border-radius: 100px;
    padding: 4px 12px;

    @media (max-width: 992px) {
        border-radius: 15px;
    }
}

.vestax-navbar-logo {
    height: 32px;
    width: auto;
    margin-top: 6px;
    margin-bottom: 6px;
}

.header_connect_but {
    background: $secondary-bg-color;
    border-radius: 100px;
    padding: 6px 15px;

    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 8px;

    transition: 0.3s all ease-in-out;
    color: white;

    .wallet_connect_icon {
        width: 25px;
        height: 25px;

        background: #d9d9d9;
        border-radius: 50px;
        color: #2a172f;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 1.375rem;
    }

    &:hover {
        background: $primary;

        .wallet_connect_icon {
            background: $secondary-bg-color;
            color: $primary;
        }

        span {
            color: $secondary-bg-color;
        }
    }

    margin-left: 6px;
    margin-right: 6px;

    @media (max-width: 992px) {
        margin-top: 6px;
        margin-bottom: 6px;
    }
}

/* footer */

.footer {
    background: #29053b;
    margin-top: 80px;
    color: #969696;
}

.footer-social {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;

    i {
        color: white;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        transition: all 0.42s ease;

        &:hover {
            background: $primary;
        }
    }
}

.social-box {
    font-size: 1.375rem;
    color: white;

    &:hover {
        color: #f1dc46;
    }
}

.contact-email {
    color: $primary;
}

.synchronic-software {
    font-size: 13px;
    font-weight: 600;
    color: $primary;

    text-decoration: none;

    &:hover {
        color: white;
    }
}

/* main */

.vesta_x_swap_card {
    background: $secondary-bg-color;
    border-radius: 15px;
    padding: 36px 16px 28px 16px;

    @include md-up-device() {
        min-width: 450px;
    }
}

.vesta_x_swap_input_box {
    // min-height: 100px;

    padding: 15px;

    background: #1b0921;
    border-radius: 15px;

    border: 1px solid rgba(255, 255, 255, 0.06);
}

.swap_input {
    outline: none;
    border: none;
    background-color: transparent;
    height: 50px;
    font-size: 2rem;
    font-weight: 400;
    padding-right: 10px;
    width: 100%;
    color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.select-token-but {
    padding: 6px 10px;
    // background: #627EEA;
    background: #3e2964;
    border: none;
    border-radius: 50px;
    outline: none;
    color: white;
    width: 160px;
    font-size: 1.25rem;
    font-weight: 600;
}

.selected-token-but {
    padding: 6px 10px;
    background: $secondary-bg-color;
    border: none;
    border-radius: 50px;
    outline: none;
    color: white;
    width: 140px;
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    gap: 6px;
    align-items: center;
}

.add-liquidity-select-token-buttton {
    padding: 6px 10px;
    background: #3e2964;
    border: none;
    border-radius: 50px;
    outline: none;
    color: white;
    width: 300px;
    font-size: 1.25rem;
    font-weight: 600;
}

.add-liquidity-selected-token-buttton {
    padding: 6px 10px;
    background: $secondary-bg-color;
    border: none;
    border-radius: 50px;
    outline: none;
    color: white;
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // gap: 2rem;
    width: 180px;

    img {
        margin-right: 0.25rem;
    }
}

.selected-pool-buttton {
    padding: 6px 10px;
    background: $secondary-bg-color;
    border: none;
    border-radius: 50px;
    outline: none;
    color: white;
    font-size: 1.25rem;
    font-weight: 600;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    img {
        margin-right: 0.25rem;
    }
}

//
.ReactModal__Overlay {
    background: rgba(27, 0, 40, 0.5) !important;
    z-index: 998;
    backdrop-filter: blur(10px);
}

.ReactModal__Content {
    background: $secondary-bg-color !important;
    border-radius: 15px !important;
    border: none !important;
    padding: 20px 0px !important;
    font-family: $font-poppins !important;
}

.select-token-modal {
    width: 450px;

    @include sm-down-device() {
        width: 350px;
    }
}

.search-token {
    width: 100%;

    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 10px;

    padding: 10px;
    background: #1b0921;

    font-size: 1.2rem;
    outline: none;
    color: white;

    &:focus {
        border: 1px solid rgba(255, 255, 255, 0.15);
        background: $secondary-bg-color;
    }
}

.modal-close-but {
    font-size: 2rem;
    color: white;
    cursor: pointer;
}

.token-tag {
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 10px;

    display: flex;
    align-items: center;
    gap: 5px;
    padding: 6px;

    cursor: pointer;

    &:hover {
        background: #1b0921;
    }
}

.token-list-box {
    height: 400px;
    overflow-y: scroll;

    @include sm-down-device {
        height: 350px;
    }
}

.token-li {
    padding: 8px;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
        background: #1b0921;
        border-radius: 10px;
    }
}

.swap-pool-row {
    padding: 8px;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    &:hover {
        background: #1b0921;
        border-radius: 10px;
    }
}

.scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #1b0921;
    border-radius: 100vh;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: $primary;
}

.exchange_but {
    width: 40px;
    height: 40px;

    border-radius: 10px;
    border: 3px solid $secondary-bg-color;

    background: #260d2d;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;
    color: white;

    cursor: pointer;

    &:hover {
        background: #1d0922;
    }
}

.BToken_Input {
    border-radius: 15px 15px 0 0 !important;
}

.swap-info-box {
    background: #1b0921;
    border-radius: 0 0 15px 15px;

    padding: 15px;
    color: white;

    border: 1px solid rgba(255, 255, 255, 0.06);
}

.swap-info-box-round {
    background: #1b0921;
    border-radius: 15px;

    padding: 15px;
    color: white;

    border: 1px solid rgba(255, 255, 255, 0.06);
}

.swap-info {
    border: 1px solid #2c1b36;
    border-radius: 15px;
    padding: 15px;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

.swap-info-li {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-bottom: 0.35rem;

    span:first-child {
        color: $secondary-text-color;
    }
}

.vesta_x_pool_card {
    background: $secondary-bg-color;
    border-radius: 15px;
    padding: 20px 20px 40px 20px;
    // width: 900px;
    min-height: 400px;

    @include sm-down-device {
        padding: 15px 8px 30px 8px;
    }
}

.token-pair-box {
    background: #1b0921;
    padding: 15px 10px;

    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 15px;

    display: flex;
    justify-content: center;
    color: #f0f0f0;
}

.tab-box {
    padding: 3px;
    background: #1b0921;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    display: flex;

    position: relative;
    color: white;
    z-index: 0;
}

.tab-focus {
    width: 150px;
    position: absolute;
    background: $secondary-bg-color;
    border-radius: 10px;
    height: 40px;
    transition: 0.3s all ease-in-out;

    @include sm-down-device {
        width: 120px;
    }
}

.tab-focus-left-0 {
    left: 3px;
}

.tab-focus-left-20 {
    left: 20%;
}

.tab-focus-left-25 {
    left: 25%;
}

.tab-focus-left-33 {
    left: 33.33%;
}

.tab-focus-left-40 {
    left: 40%;
}

.tab-focus-left-50 {
    left: 50%;
}

.tab-focus-left-60 {
    left: 60%;
}

.tab-focus-left-66 {
    left: 66.66%;
}

.tab-focus-left-75 {
    left: 75%;
}

.tab-focus-left-80 {
    left: 80%;
}

.tab-focus-left-100 {
    left: 100%;
}

.tab {
    width: 150px;
    height: 40px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-size: 1.1rem;
    font-weight: 300;
    cursor: pointer;
    text-align: center;

    @include sm-down-device {
        width: 120px;
    }
}

.active-pool-li-container {
    background: #1b0921;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    padding: 15px;
}

.active-pool-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #2c1b36;
    border-radius: 15px;
    padding: 2rem;

    .active-pool-pair {
        display: flex;
        align-items: center;
        width: 20%;

        @include lg-down-device {
            width: 100%;
        }
    }

    .active-pool-info {
        display: flex;
        flex-direction: column;
        color: white;
        font-size: 1.1rem;
        justify-content: center;
        text-align: center;
        row-gap: 5px;

        // width: 50px;
        align-items: center;

        span:first-child {
            color: $secondary-text-color;
            font-size: 1rem;
        }

        @include lg-down-device {
            flex-direction: row;
            justify-content: space-between;
            // width: 100%;
        }
    }

    @include lg-down-device {
        flex-direction: column;
        row-gap: 5px;
        align-items: unset !important;
    }
}

@include lg-up-device {
    .pool-row-item-1 {
        width: 30%;
    }

    .pool-row-item-2 {
        width: 18%;
    }

    .pool-row-item-3 {
        width: 18%;
    }

    .pool-row-item-4 {
        width: 18%;
    }
}

.fee-badge {
    padding: 3px 5px;

    border: 3px solid $secondary-bg-color;
    border-radius: 10px;

    color: $secondary-text-color;
    background: #260d2d;
}

.state-active {
    color: #0df365 !important;
}

.state-active-no-swaps {
    color: var(--bs-warning) !important;
}

.state-inactive {
    color: var(--bs-red) !important;
}

.vesta_x_staking_card {
    background: $secondary-bg-color;
    border-radius: 15px;
    padding: 20px 20px 40px 20px;
    width: 1200px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    min-height: 40rem;

    @include sm-down-device {
        padding: 15px 8px 30px 8px;
        min-height: 30rem;
    }
}

.stake-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: white;
    font-size: 1.1rem;
    margin-left: 10px;
    text-align: center;

    span:first-child {
        color: $secondary-text-color;
        font-size: 1.2rem;
    }
}

.vesta_x_but {
    padding: 6px 20px;
    background: $secondary-bg-color;
    border-radius: 100px;
    cursor: pointer;
    color: white;
    text-align: center;
    transition: 0.3s all ease-in-out;

    &:hover {
        background: #627eea;
    }
}

// liquidity
.add-liquidity-pool-box {
    background: #1b0921;
    padding: 15px 10px;

    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 15px;

    display: flex;
    justify-content: center;
}

.add-liquidity-input-token-balance-box {
    display: flex;
    justify-content: space-between;
    color: #98a1c0;
    margin-right: 1rem;
    font-size: 0.9rem;
    cursor: pointer;
}

.pool-collapse-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 3rem;
    height: 3rem;
    background: #1b0921;

    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 100px;
    cursor: pointer;
    color: white;
    text-align: center;
    transition: 0.3s all ease-in-out;

    font-size: 1.15rem;

    &:hover {
        background: #627eea;
    }
}

.go-back-button {
    display: block;
    width: 3rem;
    height: 3rem;
    background: #1b0921;

    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 100px;
    cursor: pointer;
    color: white;
    text-align: center;
    transition: 0.3s all ease-in-out;

    font-size: 1.15rem;

    &:hover {
        background: #627eea;
    }
}

.add-liquidity-but {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.6rem 22px;
    background: #110017;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 20px;
    text-align: center;
    transition: 0.3s all ease-in-out;

    font-size: 1.05rem;
    color: rgba(255, 255, 255, 0.8);

    &:hover {
        background: #627eea;
    }

    &:disabled {
        color: #888;
        background: #25252544;
        cursor: not-allowed;
    }
}

.add-liquidity-button {
    display: block;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.6rem 20px;
    background: #1b0921;

    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 100px;
    cursor: pointer;
    color: white;
    text-align: center;
    transition: 0.3s all ease-in-out;

    font-size: 1.15rem;

    &:hover {
        background: #627eea;
    }

    &:disabled {
        color: #888;
        background: #25252544;
        cursor: not-allowed;
    }
}

.my-liquidity-no-liquidity {
    text-align: center;
    color: #98a1c0;
    font-size: 1.25rem;
}

.input-token-error {
    color: var(--bs-red);
    font-size: 0.8rem;
    margin-left: 3px;
}

// create-pool
.cp-select-token-button {
    padding: 6px 15px;
    background: #3e2964;
    border: none;
    border-radius: 50px;
    outline: none;
    color: white;
    font-size: 1.25rem;
    font-weight: 600;
    width: 100%;

    display: flex;
    justify-content: space-between;
    gap: 6px;
    align-items: center;
}

.cp-select-token-button.selected {
    background: $secondary-bg-color;
}

.cp-input-token-error {
    color: var(--bs-red);
    font-size: 0.8rem;
}

.cp-init-input {
    outline: none;
    border: none;
    background-color: transparent;
    height: 32px;
    font-size: 1.2rem;
    font-weight: 400;
    padding-right: 10px;
    width: 100%;
    color: white;
}

.cp-init-input-container {
    padding: 6px 12px;
    background: #1b0921;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    color: white;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-right {
    text-align: right;
}

.cp-label-container {
    margin-top: 1.5rem;
    margin-left: 0.5rem;
    color: white;
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-start;

    span:first-child {
        display: inline-block;
        width: 190px;
        color: #939da7;
        font-size: 1rem;
    }
}

//
.but-setting {
    color: white;
    font-size: 1.5rem;
    margin-right: 15px;
    cursor: pointer;

    &:hover {
        color: $third;
    }
}

.slippage-setting-modal {
    padding: 20px;
    width: 450px;

    @include sm-down-device() {
        width: 350px;
    }

    .slippage-title {
        color: $third;
        font-size: 14px;
    }
}

.slippage-but {
    color: white;
    padding: 6px 12px;
    background: #1b0921;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    width: 100px;
    // display: flex;
    text-align: center;
    font-size: 1.1rem;
    width: 70px;

    &:hover {
        background: #3e2964;
    }
}

.slippage-but-selected {
    background: #627eea;
}

.slippage-setting-modal-but {
    border: 1px solid rgba(255, 255, 255, 0.06);
    background: #1b0921;
    border-radius: 100px;
    color: white;
    padding: 10px 30px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    font-size: 16px;

    &:hover {
        background: #627eea;
    }
}

.input-box {
    display: flex;
    align-items: center;
    outline: none;
    background: #1b0921;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    color: white;
    text-align: right;
    width: 100%;
    padding-right: 0.5rem;
    font-size: 15px;
}

.input-box input {
    flex-grow: 1;
    border: none;
    outline: none;
    width: 100%;
    background: #1b0921;
    text-align: right;
    color: white;
}

.input-box:focus-within {
    border-color: #777;
}

.slippage-warn {
    color: #f3841e;
    font-size: 13px;
}

.error-text {
    color: red;
    font-size: 0.9rem;
}

.connection-modal {
    width: 450px;
    padding: 20px;

    @include sm-down-device() {
        width: 350px;
    }
}

.demi-footer-logo {
    width: 190px;

    @include md-device() {
        width: 100%;
    }
}

.dark-bg-footer {
    background: #1f042c;
    padding: 50px 0px;

    @include sm-down-device {
        padding: 30px 0px;
    }
}

.contact-box {
    text-align: left;

    @include md-down-device {
        text-align: center;
    }
}

.copyright-box {
    text-align: right;

    @include md-down-device {
        text-align: center;
    }
}

.account-address-box {
    padding: 10px;
    background: #1b0921;
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    color: #98a1c0;

    display: flex;
    align-items: center;

    .avatar {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $secondary-bg-color;
        font-size: 1.5rem;
        border-radius: 100px;
        border: 1px solid rgba(255, 255, 255, 0.06);
        margin-right: 8px;
    }
}

.vesta_x_dashboard_card {
    background: $secondary-bg-color;
    border-radius: 15px;
    padding: 20px 20px 40px 20px;
    width: 1200px;
    display: flex;
    flex-direction: column;
    // row-gap: 20px;

    color: #98a1c0;

    @include sm-down-device {
        padding: 15px 8px 30px 8px;
    }

    .dashboard_card_header {
        padding: 15px;
        background: #1b0921;
        border: 1px solid rgba(255, 255, 255, 0.06);
        border-radius: 15px 15px 0 0;
        font-size: 1.2rem;
    }

    .dashboard_card_body {
        padding: 15px;
        background: #1b0921;
        border: 1px solid rgba(255, 255, 255, 0.06);
        border-radius: 0 0 15px 15px;

        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .breakdown-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
    }

    .custom-accordion {
        font-size: 1rem;

        gap: 10px;

        .accordion-item {
            margin-top: 10px;
            padding: 0.5rem 1rem;
            background-color: #1b0921;
            border: 1px solid rgba(255, 255, 255, 0.06);
            border-radius: 10px;
        }

        .accordion-header {
            width: 100%;
            margin: 0rem;
        }

        .accordion-button {
            width: 100%;
            padding: 0.5rem 0rem;
            color: white;
            box-shadow: none !important;
            border: none;
            font-size: 1.1rem;
            font-weight: 400;
            background: transparent;
            display: flex;
            align-items: center;
        }

        .accordion-body {
            margin-top: 10px;
            color: #98a1c0;
        }
    }
}

.mini-but {
    font-size: 1.25rem;
    color: #98a1c0;
    cursor: pointer;

    &:hover {
        color: white;
    }
}

.logout-but {
    font-size: 2.25rem;
    color: #98a1c0;
    cursor: pointer;
    margin-right: 15px;
    transition: 0.3s all ease-in-out;

    &:hover {
        color: $primary;
    }
}

.vesta_x_farm_container {
    background: $secondary-bg-color;
    border-radius: 15px;
    padding: 20px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @include sm-down-device {
        padding: 15px 8px 30px 8px;
    }
}

.farm_card_header {
    padding: 10px 15px;
    background: #1b0921;
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 15px 15px 0 0;
    font-size: 1.2rem;
}

.farm_card_body {
    padding: 15px;
    background: #1b0921;
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 0 0 15px 15px;

    flex: 1;
    // display: flex;
    // flex-direction: column;
    // row-gap: 12px;
    color: #f0f0f0;
}

.w-20 {
    width: 20%;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.w-80 {
    width: 80%;
}

.spin-but {
    width: 2rem;
    height: 2rem;

    color: inherit;
    background: $secondary-bg-color;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: 0.2s all ease-in-out;

    &:hover {
        background: #627eea;
        color: white;
    }
}

.farm_but {
    background: $secondary-bg-color;
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: 0.2s all ease-in-out;
    color: white;

    &:hover {
        background: #627eea;
        color: white;
    }
}

.farm_swap_card_body {
    padding: 1rem 1rem;
    background: #1b0921;
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    row-gap: 12px;
    flex: 1;

    transition: 0.5s all ease-in-out;
}

.farm_logo_element {
    // border: 1px solid rgba(255, 255, 255, 0.06);
    // border-radius: 15px;
    padding: 5px;
    width: 80px;
}

.farm_card_individual_element_block {
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 15px;
    padding: 0.5rem;
    height: 100%;
    transition: 0.3s all ease-in-out;
    color: inherit;
    background-color: inherit;
}

.farm_card_individual_element_box {
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    padding: 10px;
    min-width: 80px;
}

.farm_card_individual_element {
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 15px;
    padding: 10px;
    height: 100%;
    transition: 0.3s all ease-in-out;
    color: inherit;
    background-color: inherit;

    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.farm_card_individual_element_but {
    max-width: 100%;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 15px;
    padding: 10px;
    height: 100%;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    color: inherit;
    background: $secondary-bg-color;

    &:hover {
        background: #627eea;
        color: white;
    }

    &:disabled {
        color: #888;
        background: #25252544;
        cursor: not-allowed;
    }
}

.farm_card_individual_element_but_red_out {
    color: #f71919 !important;
    // background: rgb(255 0 0 / 27%) !important;
}

.farm_card_individual_element.selected,
.farm_card_individual_element_but.selected,
.farm_card_individual_element_block.selected {
    // background: $secondary-bg-color;
    box-shadow: 0px 0px 2px rgba(0, 255, 255, 0.5), 0px 0px 4px rgba(0, 255, 255, 0.5),
        0px 0px 8px rgba(0, 255, 255, 0.5), 0px 0px 16px rgba(0, 255, 255, 0.5);
}

.farm-final-result {
    font-size: 1.875rem;
    display: flex;
    gap: 6px;
    flex-wrap: wrap;

    @include sm-down-device() {
        font-size: 1.5rem;
    }
}

.dapp-core-component__transactionsToastList-styles__toasts
    .dapp-core-component__transactionsToastList-styles__toastWrapper,
.dapp-core-component__transactionsToastList-styles__toasts.dapp-core-component__transactionsToastList-styles__toastWrapper {
    background: #100017 !important;
    border: 1px solid rgba(255, 255, 255, 0.06);
    color: white !important;
    border-radius: 10px;
}

.dapp-core-component__transactionToast-styles__content
    .dapp-core-component__transactionToast-styles__right
    .dapp-core-component__transactionToast-styles__heading
    .dapp-core-component__transactionToast-styles__close,
.dapp-failed-transaction-status-toast .dapp-core-component__customToast-styles__close {
    color: white !important;
}

.dapp-core-component__progressStyles__progress .dapp-core-component__progressStyles__bar {
    background: rgb(28, 11, 29);
    opacity: 0.5;
}

.elrond-address-link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: 1rem;
    color: white;

    &:hover {
        color: #f1dc46;
    }
}

//
.pool-row-label-container {
    margin-top: 0.8rem;
    margin-left: 0.5rem;
    color: white;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;

    span:first-child {
        display: inline-block;
        width: 140px;
        color: #939da7;
        font-size: 0.9rem;
    }
}

.pool-state-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 180px;
    padding: 0.5rem 1rem;
    background: #1b0921;

    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 100px;
    cursor: pointer;
    color: white;
    text-align: center;
    transition: 0.3s all ease-in-out;

    font-size: 1rem;

    &:hover {
        background: #3e2964;
    }
}

.pool-state-button.selected {
    background: #627eea;
}

.portfolio-token-row {
    border-bottom: 1px solid #4a4b4d;
}

.portfolio-token-row-common {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
}

.LPfarm-card-info {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    column-gap: 1.5rem;
    row-gap: 1rem;
    width: 100%;

    .LP-control {
        display: flex;
        column-gap: 1.5rem;
        align-items: center;
        width: 100%;

        .LP-info {
            @include lg-down-device() {
                width: calc(100% - 50px);
            }
        }
    }

    .LP-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;

        @include lg-down-device() {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    @include lg-down-device() {
        align-items: start;
        flex-direction: column;
    }
}

.farm_swap_card_swapbox {
    padding: 15px;
    background: #1b0921;
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;

    transition: 0.5s all ease-in-out;

    @include sm-down-device() {
        padding: 5px;
    }
}

.swap-back-but {
    cursor: pointer;

    &:hover {
        color: white;
    }
}

.border-top {
    border-top: 1px solid #2c1b36 !important;
}

.farm-tier-medal-glow {
    box-shadow: 0px 0px 2px rgba(180, 255, 255, 0.5), 0px 0px 4px rgba(180, 255, 255, 0.5),
        0px 0px 8px rgba(180, 255, 255, 0.5);
}

.presale-label-row {
    color: white;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;

    span:first-child {
        color: #939da7;
        // font-size: 1rem;
    }
}

.vested-token-container {
    background: #1b0921;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    padding: 15px;

    .vested-token {
        display: flex;
        flex-direction: column;
        color: white;
        font-size: 1.1rem;
        justify-content: center;
        text-align: center;
        row-gap: 5px;

        // width: 50px;
        align-items: center;

        span:first-child {
            color: $secondary-text-color;
            font-size: 1rem;
        }

        @include lg-down-device {
            flex-direction: row;
            justify-content: space-between;
            // width: 100%;
        }
    }

    @include lg-down-device {
        flex-direction: column;
        row-gap: 5px;
        align-items: unset !important;
    }
}

.pool-stats-box {
    border: 1px solid #2c1b36;
    border-radius: 15px;
    padding: 15px;

    display: flex;
    justify-content: center;
    flex-direction: row;
    // width: fit-content;
    gap: 3rem;

    @include sm-down-device {
        width: 100%;
        flex-direction: column;
        row-gap: 5px;
        align-items: unset !important;
    }
}

.pool-stats-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    row-gap: 0.5rem;
    // margin-bottom: 0.35rem;
    // gap: 1rem;

    span:first-child {
        color: $secondary-text-color;
    }

    span:nth-child(2) {
        font-size: 1.2rem;
    }

    @include sm-down-device {
        flex-direction: row;
    }
}

.text-secondary-color {
    color: $secondary-text-color;
}

.info-icon-in-corner {
    font-size: 1.2rem;
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
}

.dropdown-toggle::after {
    border: none !important;
}

.vestax-navbar-dropdown {
    font-size: 1.05rem;
    font-weight: 300;
    text-align: center;
    text-decoration: none !important;
    color: white !important;

    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        color: $primary !important;
    }

    .dropdown-toggle {
        margin: 0rem 0.25rem;
        padding: 6px 16px !important;
        border-radius: 100px;
        text-align: center;
    }

    .dropdown-menu {
        margin-top: 8px;
        background: #2a172f;

        .dropdown-item {
            &:hover {
                background-color: #3f2247 !important;
            }
        }
    }
}

.gold-color {
    border: 1px solid #ffd70088;
}

.silver-color {
    border: 1px solid #808080;
}

.bronze-color {
    border: 1px solid #cd7f3288;
}

.sft-box {
    max-width: 100%;
    display: block;
    // border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 15px;
    padding: 10px;
    height: 100%;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    color: inherit;
    background: $secondary-bg-color;

    &:hover {
        background: #627eea;
        color: white;
    }
}

.gold-color.selected {
    box-shadow: 0px 0px 2px #ffd70088, 0px 0px 4px #ffd70088, 0px 0px 8px #ffd70088, 0px 0px 16px #ffd70088;
}

.silver-color.selected {
    box-shadow: 0px 0px 2px #808080, 0px 0px 4px #808080, 0px 0px 8px #808080, 0px 0px 16px #808080;
}

.bronze-color.selected {
    box-shadow: 0px 0px 2px #cd7f3288, 0px 0px 4px #cd7f3288, 0px 0px 8px #cd7f3288, 0px 0px 16px #cd7f3288;
}

.farm-multipler-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    @include xxl-down-device() {
        justify-content: flex-start;
    }
}

.slip-li-container {
    background: #1b0921;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    padding: 20px;
}

.slip-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @include lg-down-device {
        flex-direction: column;
        row-gap: 15px;
        align-items: unset !important;
    }
}

.slip-progress {
    height: 33px;
    border-radius: 12px;
    background: #a349a2;
    font-size: 1rem;

    .slip-progress-bar {
        background: #8500c9;
        overflow: visible;
    }

    .slip-fillup-bar {
        background: #a349a2;
        margin-left: auto;
    }
}

.vesta_x_input {
    width: 100%;
    height: 44px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 8px;
    outline: none;
    color: rgba(255, 255, 255, 0.8);
    background: #110017;
    padding-left: 8px;
    padding-right: 8px;
}

.slip-but {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.6rem 22px;
    background: #110017;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 20px;
    text-align: center;
    transition: 0.3s all ease-in-out;

    font-size: 1.05rem;
    color: rgba(255, 255, 255, 0.8);

    &:hover {
        background: #627eea;
    }

    &:disabled {
        color: #888;
        background: #25252544;
        cursor: not-allowed;
    }
}

.slip-admin-panel-box {
    border: 1px solid #2c1b36;
    border-radius: 15px;
    padding: 15px;
}

.slip-li-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;

    @include lg-down-device() {
        flex-direction: row;
        justify-content: space-between;
    }
}

.vault-token-selector {
    max-width: 100%;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 8px;
    padding: 4px 6px;
    margin: 4px;
    height: 100%;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    color: inherit;
    background: transparent;

    &:hover {
        background: #74298d88;
    }

    &:disabled {
        color: #888;
        background: #25252544;
        cursor: not-allowed;
    }
}

.vault-token-selector.selected {
    box-shadow: 0px 0px 2px #74298d88, 0px 0px 4px #74298d88, 0px 0px 8px #74298d88, 0px 0px 16px #74298d88;
}

.vault-container-card {
    background: $secondary-bg-color;
    border-radius: 15px;
    padding: 20px 20px 40px 20px;
}

.nft-staking-row-info-first-section {
    display: flex;
    align-items: center;
    width: 100%;

    border-right: 1px solid #98a1c0;
    padding-right: 16px;

    @media screen and (max-width: 768px) {
        border-right: none;
        padding-right: 0px;
    }
}

.bordered-box {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    padding: 8px;
}

.mobile-responsive-number-input {
    display: flex;
    align-items: center;

    height: 42px;
    background: $secondary-bg-color;
    border-radius: 10px;
    font-size: 20px;

    .spin-but {
        width: 42px;
        height: 100%;
        color: white;
    }

    input {
        width: 80px;
        background: transparent;
        outline: none;
        border: none;
        text-align: center;
        color: white;
    }
}

.responsive-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
        flex-direction: column;
        justify-content: center;
    }
}

.nft-button {
    // transition: 0.3s all ease-in-out;
    width: 100px;
    border-radius: 10px;

    .nft-logo {
        width: 100px;
        height: 100px;
        border-radius: 10px;
    }
}

.nft-button.hover {
    &:hover {
        border: 1px solid rgba(255, 255, 255, 0.07);
        padding: 4px;

        .nft-logo {
            width: 90px;
            height: 90px;
        }
    }
}

.nft-button.selected {
    padding: 2px;
    box-shadow: 0px 0px 2px rgba(0, 255, 255, 0.5), 0px 0px 4px rgba(0, 255, 255, 0.5),
        0px 0px 8px rgba(0, 255, 255, 0.5), 0px 0px 16px rgba(0, 255, 255, 0.5);

    .nft-logo {
        width: 96px;
        height: 96px;
    }
}
.nft-card {
    position: relative;
    text-align: center;

    .staked-amount-centered {
        background: #000000c2;
        width: 40px;
        color: white;
        border-radius: 5px;

        position: absolute;
        bottom: 4px;
        right: 4px;
        font-size: 12x;
    }
}

.stable-coin-ui-box {
    @include md-up-device() {
        min-width: 450px;
    }

    @include md-down-device() {
        width: 100%;
    }
}

.vesta_x_dropdown {
    .dropdown-toggle {
        width: 100px !important;
        height: 44px;
        background: $secondary-bg-color;
        border: 1px solid rgba(255, 255, 255, 0.07);
        border-radius: 8px;
        color: rgba(255, 255, 255, 0.8);
    }

    .dropdown-menu {
        background: $secondary-bg-color;
        border: 1px solid rgba(255, 255, 255, 0.07);
        border-radius: 8px;

        .dropdown-item {
            background: $secondary-bg-color;
            border-radius: 4px;
            color: rgba(255, 255, 255, 0.8);
            padding-top: 8px;
            padding-bottom: 8px;
            transition: 0.2s all ease-in-out;
            cursor: pointer;

            &:hover {
                background: #1b0921;
            }
        }
    }
}

.nft-staking-pool-stats-box {
    width: 100%;
    // height: 100%;
    padding: 10px;

    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    color: #98a1c0;

    .box-title {
        text-align: center;
        margin-bottom: 16px;
        font-size: 1.2rem;
    }
}

.nft-staking-pool-but {
    cursor: pointer;
    padding: 10px;
    background: $secondary-bg-color;
    border: none;
    border-radius: 10px;

    color: #98a1c0;
    text-align: center;
    transition: 0.3s all ease-in-out;

    &:hover {
        background: #627eea;
        color: white;
    }
    &:disabled {
        background: #25252544;
        cursor: not-allowed;
    }
}

// Copied from Fancy's branch
.receive-token-but-group {
    display: flex;
    align-items: center;

    div:first-child {
        border-radius: 12px 0 0 12px;
    }

    div:last-child {
        border-radius: 0 12px 12px 0;
    }

    .receive-token-but {
        font-weight: 500;
        padding: 8px;
        border: 1px solid #f1dc46;
        color: #f1dc46;
        cursor: pointer;
        transition: 0.2s all ease-in-out;

        &:hover {
            background: #f1dd46c5;
            color: $secondary-bg-color;
        }
    }

    .receive-token-but.selected {
        background: #f1dd46;
        color: $secondary-bg-color;
    }
}

.balance-but {
    font-size: 0.8rem;
    cursor: pointer;
    background: transparent;
    transition: 0.3s all ease-in-out;
    border-radius: 4px;
    padding: 4px;

    &:hover {
        background: #627eea;
        color: white;
    }
}

.remove-liquidity-box {
    width: 600px;
    padding: 16px;

    @include sm-down-device() {
        width: 350px;
        padding: 8px;
    }
}

.receive-token-but-group {
    display: flex;
    align-items: center;

    div:first-child {
        border-radius: 12px 0 0 12px;
    }

    div:last-child {
        border-radius: 0 12px 12px 0;
    }

    .receive-token-but {
        font-weight: 500;
        padding: 8px;
        border: 1px solid #f1dc46;
        color: #f1dc46;
        cursor: pointer;
        transition: 0.2s all ease-in-out;

        &:hover {
            background: #f1dd46c5;
            color: $secondary-bg-color;
        }
    }

    .receive-token-but.selected {
        background: #f1dd46;
        color: $secondary-bg-color;
    }
}
