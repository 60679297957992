@import './theme.scss';

.vault-row-container {
    background: #1b0921;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    padding: 1rem;
}

.vault-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .row-part-w20 {
        width: 20%;
        flex: 1 1 auto;

        @include lg-down-device {
            width: 100%;
        }
    }

    .row-part-w30 {
        width: 30%;
        flex: 1 1 auto;

        @include lg-down-device {
            width: 100%;
        }
    }

    .active-pool-info {
        display: flex;
        flex-direction: column;
        color: white;
        font-size: 1.1rem;
        justify-content: center;
        text-align: center;
        row-gap: 5px;

        // width: 50px;
        align-items: center;

        span:first-child {
            color: $secondary-text-color;
            font-size: 1rem;
        }

        @include lg-down-device {
            flex-direction: row;
            justify-content: space-between;
            // width: 100%;
        }
    }

    @include lg-down-device {
        flex-direction: column;
        row-gap: 5px;
        align-items: unset !important;
    }
}

.row-part-with-border {
    border: 1px solid #2c1b36;
    border-radius: 10px;
}

.vault-image-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > img {
        display: block;
        max-width: 31%;
        width: auto;
        height: auto;
    }
}

.vault-sft-staking-container {
    padding: 0.8rem 0.5rem;
    // background: #1B0921;
    border: 1px solid #2c1b36;
    // border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    transition: 0.5s all ease-in-out;

    .sft-tag {
        border: 1px solid rgba(255, 255, 255, 0.06);
        border-radius: 15px;
        // padding: 10px;
        padding: 0.9rem 0.2rem;
        height: 100%;
        font-size: 0.9rem;
        transition: 0.3s all ease-in-out;
        color: inherit;
        background-color: inherit;

        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
}

.sft-staking-spin-container {
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    padding: 0.5rem;
    // height: 100%;
    transition: 0.3s all ease-in-out;
    color: inherit;
    background-color: inherit;

    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.farm_but {
    &:disabled {
        color: #888 !important;
        background: #25252544 !important;
        cursor: not-allowed;
    }
}

.vesta-button-first {
    display: block;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.6rem 20px;
    background: #1b0921;

    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 100px;
    cursor: pointer;
    color: white;
    text-align: center;
    transition: 0.3s all ease-in-out;

    font-size: 1.15rem;

    &:hover {
        background: #627eea;
    }

    &:disabled {
        color: #888;
        background: #25252544;
        cursor: not-allowed;
    }
}

.select-borrow-currency-dropdown {
    background: #100017;
    border: none;
    border-radius: 50px;
    outline: none;
    color: white;
    width: 180px;

    .dropdown-menu.show {
        transform: translate(0px, 32px) !important;
        width: 100%;
        background: #1b0921;
        color: white;
        border: 1px solid rgba(255, 255, 255, 0.06);
    }

    .dropdown-item {
        color: white;

        &:hover {
            color: black;
        }
    }

    button {
        width: 100%;
        background-color: transparent;
        border: none;
        color: white;
        padding: 6px 10px;
        font-size: 1.25rem;
        font-weight: 600;
        align-items: center;
        border-radius: 50px;
    }
    button:active {
        background-color: transparent;
        border: none;
        color: white;
    }
}

.currency-item {
    display: flex;
    img {
        width: 25px;
        height: 25px;
    }
    div {
        margin-left: 5px;
        display: flex;
        // align-items: center;
    }
}

.coil-input {
    background: $secondary-bg-color;
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    outline: none;
    height: 40px;
    color: white;
    width: 100%;
    text-align: right;
    padding-right: 10px;
    padding-left: 10px;
}

.coil-input.disabled {
    background: #1000176e;
    cursor: not-allowed;
}

.coil-but {
    background: $secondary-bg-color;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    color: white;

    border-radius: 8px;
    padding: 4px 8px;
    border: 1px solid rgba(255, 255, 255, 0.06);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
        background: #627eea;
        color: white;
    }

    &:disabled {
        color: #888 !important;
        background: #25252544 !important;
        cursor: not-allowed;
    }
}

// .coil-but.disabled {
//     background: gray;
//     cursor: not-allowed;
// }

.coil-box {
    border-radius: 8px;
    padding: 4px 8px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coil-row-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
    }
}

.uncoil-timer {
    .presale-timer-container {
        color: #98a1c0;
        font-size: 0.8rem;
    }
}
