.assets-token-box {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  @include sm-down-device() {
    margin-bottom: 1rem;
  }

  padding: 1rem;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #1B0921;

  .title-top {
    margin-bottom: .4rem;
    color: #F1DC46;
    font-size: 1rem;
    text-align: center;
  }

  & > :nth-child(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      height: 4rem;
      width: auto;
    }

    & > :nth-child(2) {
      font-size: 1.1rem;
      color: #f0f0f0;
      text-align: right;
    }
  }
}

.second-card-style {
//   border: 1px solid rgba(255, 255, 255, 0.06);
    border: 1px solid #2c1b36;
    border-radius: 10px;
    padding: 1rem .5rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
    // margin-bottom: 1.5rem !important;
}

.token-stats-title {
  text-align: center;
  margin-bottom: .4rem;
  font-size: 1rem;
}

.assets-token-box-second {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .3rem .5rem;
  margin-bottom: .4rem;

  border-radius: 5px;
  border: 1px solid transparent;
  background: #1B0921;

  img {
    height: 2rem;
    width: auto;
  }

  & > :nth-child(2) {
    font-size: 1rem;
    color: #f0f0f0;
    text-align: right;
  }
}

.assets-token-box-second.size-3x {
  height: 9.2rem;

  img {
    height: 100%;
    width: auto;
  }
}

.assets-token-box-second.size-2x {
  height: 4.4rem;

  img {
    height: 100%;
    width: auto;
  }
}

.assets-token-box-second.size-25x {
  height: 5.4rem;

  img {
    height: 100%;
    width: auto;
  }
}

.clickable {
  cursor: pointer;
  transition: .2s all ease-in-out;
  &:hover {
    background: #74298d88;
    border: 1px solid #ffffff0f;
  }
}

.clickable.selected {
  box-shadow: 0px 0px 2px #74298d88, 
            0px 0px 4px #74298d88, 
            0px 0px 8px #74298d88,
            0px 0px 16px #74298d88;
}

.assets-token-box-third {
  padding: .3rem .5rem;
  margin-bottom: .4rem;
  font-size: .9rem;

  border-radius: 5px;
  border: 1px solid transparent;
  background: #1B0921;

  & > :nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > :nth-child(1) {
      color: #939da7;
    }
    & > :nth-child(2) {
      color: #f0f0f0;
      text-align: right;
    }
  }

  & > :nth-child(2) {
    color: #939da7;
    text-align: right;
  }
}

.assets-button {
  display: block;
  width: 180px;
  padding: .6rem 20px;
  background: #1B0921;

  @include sm-down-device() {
    width: 10rem !important;
  }

  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 100px;
  cursor: pointer;
  color: white;
  text-align: center;
  transition: .3s all ease-in-out;

  font-size: 1.15rem;

  &:hover {
    background: #627EEA;
  }

  &:disabled {
    color: #888;
    background: #25252544;
    cursor: not-allowed;
  }
}

.assets-token-box-fourth {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @include sm-down-device() {
    img {
      height: 5rem !important;
    }
  }

  padding: 1rem;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #1B0921;

  img {
    height: 6rem;
    width: auto;
  }

  & > :nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .title-top {
      margin-bottom: .4rem;
      color: #F1DC46;
      font-size: 1rem;
      text-align: center;
    }

    & > :nth-child(2) {
      font-size: 1.1rem;
      color: #f0f0f0;
      text-align: right;
    }
  }
}

.tab-box-second {
  padding: 3px;
  background: #1B0921;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  display: flex;

  position: relative;
  color: white;
  z-index: 0;

  .tab-focus {
    width: 10rem;
    position: absolute;
    background: $secondary-bg-color;
    border-radius: 10px;
    // height: 4rem;
    transition: .3s all ease-in-out;
  
    @include sm-down-device {
      width: 8rem;
    }
  }
  
  .tab-focus-left-0 {
    left: 3px;
  }

  .tab-focus-left-25 {
    left: 25%;
  }
  
  .tab-focus-left-50 {
    left: 50%;
  }
  
  .tab-focus-left-75 {
    left: 75%;
  }
  
  .tab {
    width: 10rem;
    // height: 4rem;
  
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  
    color: white;
    font-size: 1.1rem;
    font-weight: 300;
    cursor: pointer;
    text-align: center;
  
    @include sm-down-device {
      width: 8rem;
    }
  }
}


.assets-liquidity-button {
  display: block;
  width: 180px;
  padding: .6rem 20px;
  background: transparent;

  color: #F1DC46;

  @include sm-down-device() {
    width: 10rem !important;
  }

  border: 1px solid rgba(255, 255, 255, 0.00);
  border-radius: 100px;
  cursor: pointer;
  text-align: center;
  transition: .3s all ease-in-out;

  font-size: 1.15rem;

  &:hover {
    background: #627EEA;
  }

  &:disabled {
    color: #888;
    background: #25252544;
    cursor: not-allowed;
  }
}