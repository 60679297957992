@import '../assets/sass/theme.scss';

.dapp-core-component__main__btn-primary {
    background-color: white !important;
    border-color: white !important;
    box-shadow: none !important;
}

.dapp-core-component__main__btn-primary div {
    box-shadow: none !important;
}

.dapp-modal-dialog-content {
    background-color: #1B0921 !important;
    border: none !important;
    color: white !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;

    .dapp-modal-dialog-header {
        padding-top: 20px !important;
        background-color: #1B0921 !important;
        color: white !important;
    }
}

.dapp-core-component__main__card {
    background-color: #1B0921 !important;
    color: white !important;
}

.dapp-core-component__main__btn {
    color: white !important;
    background-color: $secondary-bg-color !important;
    border: none !important;
    text-decoration: none !important;
}

.dapp-modal-dialog-close-button {
    margin: 5px !important;
}

.wallet-login {
    // width: 95% !important;
    background: #1B0921 !important;
    border-radius: 10px !important;
    border: 1px solid rgba(255, 255, 255, 0.06) !important;
    padding: 15px !important;
    color: white !important;
    font-size: 1.25rem !important;
    text-decoration: none !important;

    &:hover {
        background: #2d0f37 !important;
    }
}

.card-body {
    background: $secondary-bg-color;
    border-radius: 15px;
}

.dapp-core-component__extensionLoginButtonStyles__no-extension-button-wrapper {
    margin-left: 1rem !important;
}

.cls-maiar-extension-1 {
    fill: #fff;
}

.cls-maiar-extension-2 {
    fill: #000;
}

.cls-maiar-app-1 {
    fill: #fff;
}

.cls-maiar-app-2 {
    fill: #000;
}

.cls-webwallet-1 {
    fill: #fff;
}

.cls-webwallet-2 {
    fill: #000;
    fill-rule: evenodd;
}

.wallet-content {
    display: flex;
    align-items: center;
    text-decoration: none !important;

    .wallet-icon {
        width: 40px;
        margin-right: 10px;
    }
}

.dapp-wallet-connect-login-modal {
    justify-content: center !important;
}

.dapp-modal-dialog-wrapper {
    justify-content: center !important;
}